import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutSection from "../components/index/AboutSection";

const pageSchemaContent = {
  name: "About",
  "@type": "AboutPage",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://imchetanyadav.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About",
        item: "https://imchetanyadav.com/about",
      },
    ],
  },
};

export default (props: any) => {
  const data = props.data.allFile.edges[0].node.childPageJson;

  return (
    <Layout>
      <SEO
        pageTitle="About"
        pageDescription={data.hero.subtitle}
        pageSchemaContent={pageSchemaContent}
        pageKeywords={[`about`]}
      />
      <AboutSection {...data.hero} />
    </Layout>
  );
};

export const aboutQuery = graphql`
  query {
    allFile(
      filter: { name: { eq: "about" }, sourceInstanceName: { eq: "content" } }
    ) {
      edges {
        node {
          childPageJson {
            hero {
              focusText
              title
              subtitle
              extraDetails
            }
          }
        }
      }
    }
  }
`;
