import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import Theme, { darkThemeColors } from "../theme";
import "./layout.css";

interface Props {}

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const [isLightTheme, updateIsLightTheme] = React.useState(
    typeof localStorage !== "undefined" && localStorage.getItem("theme") === "dark" ? false : true
  );

  return (
    <ThemeProvider
      theme={isLightTheme ? Theme : { ...Theme, colors: darkThemeColors }}
    >
      <>
        <main>
          <div
            style={{
              minHeight: "calc(100vh)",
              color: isLightTheme
                ? Theme.colors.foreground
                : darkThemeColors.foreground,
              backgroundColor: isLightTheme
                ? Theme.colors.background
                : darkThemeColors.background,
            }}
            data-scroll={true}
          >
            {children}
          </div>
        </main>
        <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}>
          <Button
            onClick={() => {
              localStorage.setItem("theme", isLightTheme ? "dark" : "light");
              updateIsLightTheme(!isLightTheme);
            }}
            style={{
              color: isLightTheme
                ? Theme.colors.foreground
                : darkThemeColors.foreground,
              backgroundColor: isLightTheme
                ? Theme.colors.background
                : darkThemeColors.background,
            }}
          >
            <Box>
              {isLightTheme ? "Turn on Dark Theme" : "Turn off Dark Theme"}
            </Box>
            <svg
              style={{
                position: "absolute",
                top: 15,
                right: 5,
                width: 30,
                height: 30,
              }}
            >
              <path
                fill="currentColor"
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18V2c4.41 0 8 3.59 8 8s-3.59 8-8 8z"
              ></path>
            </svg>
          </Button>
        </div>
      </>
    </ThemeProvider>
  );
};

export default Layout;

const Box = styled("div")`
  position: absolute;
  top: 15px;
  display: block;
  opacity: 0;
  overflow: hidden;
  width: 100%;
`;

const Button = styled("button")`
  outline: none;
  align-content: center;
  align-items: center;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  padding: 12px;
  z-index: 30;
  transition: width 0.5s ease;
  width: 50px;
  height: 50px;
  overflow: hidden;
  &:hover {
    width: 240px;
    align-items: end;
    ${Box} {
      opacity: 1;
      transition: opacity 0.5s linear;
    }
  }
`;
