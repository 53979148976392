import * as React from "react";
import styled, { CSSProp } from "styled-components";
import {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  breakpoints,
  space,
} from "../theme";
import { BoxProps } from "rebass";

export const FocusText = styled("p")`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes[2]}px;
  font-weight: ${fontWeights.medium};
  color: ${props => props.theme.colors.foreground};
  text-transform: uppercase;
  word-spacing: 3px;
  margin-bottom: 1.5rem;
  transition: color 0.3s ease-in-out;

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[2]}px;
    /* text-align: center; */
  }
`;

export const Title = styled("h2")`
  font-size: ${fontSizes[8]}px;
  line-height: ${fontSizes[9]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.medium};
  color: ${props => props.theme.colors.foreground};
  transition: color 0.3s ease-in-out;

  @media (max-width: ${breakpoints[1]}) {
    text-align: "inherit";
    /* align-self: center; */
  }
  @media (max-width: ${breakpoints[0]}) {
    font-size: ${fontSizes[7]}px;
    line-height: ${fontSizes[7]}px;
    /* text-align: center; */
  }
`;

export const Para = styled("p")`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes[4]}px;
  font-weight: ${fontWeights.regular};
  color: ${props => props.theme.colors.foreground};
  word-spacing: 3px;
  margin-bottom: 2rem;
  transition: color 0.3s ease-in-out;

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[3]}px;
    /* text-align: center; */
  }
`;

interface SectionProps {
  center?: boolean;
  fullWidth?: boolean;
  style?: CSSProp;
}
// eslint-disable-next-line
export const StyledSection = styled.section<SectionProps>`
  padding: ${props => `${props.theme.space[10]}px 6vw`};
  text-align: ${props => (props.center ? "center" : "left")};

  & > p {
    margin-bottom: ${space[7]}px;
  }

  @media (max-width: ${breakpoints[1]}) {
    text-align: left;
  }
`;
export const Section:React.FC<SectionProps> = ({ children, fullWidth, ...others }: any) => (
  <StyledSection {...others}>
    <div style={{ maxWidth: fullWidth ? "none" : "1200px", margin: "0 auto", height: "100%" }}>
      {children}
    </div>
  </StyledSection>
);

export const PrimaryButton = styled("button")`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes[4]}px;
  background: ${props => props.theme.colors.accent};
  color: ${props => props.theme.colors.background};
  outline: none;
  border: none;
  cursor: pointer;
  padding: ${space[2]}px ${space[6]}px;
  border-radius: 40px;
  margin: ${space[4]}px 0;
  font-weight: ${fontWeights.medium};
  transition: color 0.2s ease-in-out, backgroundColor 0.2s ease-in-out;

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[2]}px;
  }
`;

export const SecondaryButton = styled("button")`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes[4]}px;
  background: ${colors.secondary};
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  padding: ${space[3]}px ${space[8]}px;
  border-radius: 4px;
  margin: ${space[4]}px 0;
  font-weight: ${fontWeights.black};

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[2]}px;
  }
`;

export const SlideButton = styled("button")`
  cursor: pointer;
  display: inline;
  padding: ${space[4]}px ${space[6]}px;
  border: 1px solid ${colors.primary[1]};
  border-radius: ${space[1]}px;
  background: 0 0;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  background: #fff;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${colors.primary[1]};
  }

  &:hover {
    color: ${colors.white};
    border-color: ${colors.primary[1]};
  }
  &:hover:after {
    width: 100%;
  }
`;

export const Input = styled("input")`
  height: ${space[7]}px;
  margin-bottom: ${space[5]}px;
  padding: ${space[2]}px;
  padding-bottom: 0;
  border: 0;
  box-shadow: none;
  background: 0 0;
  border-bottom: 1px solid ${colors.grey[6]};
  font-size: ${fontSizes[1]}px;
  letter-spacing: 1px;
  font-weight: ${fontWeights.regular};
  border-radius: 0;

  &:focus {
    outline: none;
    border-bottom-color: ${colors.primary[1]};
  }
`;

export const Textarea = styled("textarea")`
  margin-bottom: ${space[5]}px;
  padding: ${space[2]}px;
  padding-bottom: 0;
  border: 0;
  box-shadow: none;
  background: 0 0;
  border-bottom: 1px solid ${colors.grey[6]};
  font-size: ${fontSizes[1]}px;
  letter-spacing: 1px;
  font-weight: ${fontWeights.regular};
  border-radius: 0;

  &:focus {
    outline: none;
    border-bottom-color: ${colors.primary[1]};
  }
`;

export const IconContainer = styled("span")`
  color: #aaa;
  margin: 0 ${space[1]}px;
  transition: color 0.4s ease-in-out;

  &:hover {
    color: #4d4f54;
  }

  &.mail:hover {
    color: #4d4f54;
  }
  &.twitter:hover {
    color: #63cdf1;
  }
  &.dribbble:hover {
    color: #e46387;
  }
  &.linkedin:hover {
    color: #90cadd;
  }
  &.instagram:hover {
    color: #4d4f54;
  }
  &.skype:hover {
    color: #36c4f3;
  }
`;

export const OnlyLargeScreenBox = styled("div")`
  @media (max-width: 45em) {
    display: none;
  }
`;

export const OnlySmallScreenBox = styled("div")`
  @media (min-width: 45em) {
    display: none;
  }
`;

const DividerWrapper = styled("div")`
  display: table;
  white-space: nowrap;

  &:before,
  &:after {
    position: relative;
    top: 50%;
    display: table-cell;
    width: 50%;
    border-top: 1px solid #e8e8e8;
    transform: translateY(50%);
    content: "";
  }

  & > span {
    display: inline-block;
    // @ts-ignore
    margin: ${props => (props.noMargin ? "0" : "0 20px")};
  }
`;

export const Divider: React.FunctionComponent<BoxProps> = ({
  children,
  ...props
}) => (
  <DividerWrapper {...props} noMargin={!children}>
    <span>{children}</span>
  </DividerWrapper>
);

export default {
  FocusText,
  Title,
  Para,
  Section,
  PrimaryButton,
  SecondaryButton,
  SlideButton,
  Input,
  Textarea,
  IconContainer,
  OnlyLargeScreenBox,
  OnlySmallScreenBox,
  Divider,
};
