import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const schemaContent = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  name: "Chetan Yadav",
  image: "https://imchetanyadav.com/img/pic.jpg",
  logo: "https://imchetanyadav.com/img/logo.png",
  author: {
    "@type": "Person",
    name: "Chetan Yadav",
    email: "imchetanyadav@gmail.com",
    telephone: "+918882868919",
  },
  creator: {
    "@type": "Person",
    name: "Chetan Yadav",
    email: "imchetanyadav@gmail.com",
    telephone: "+918882868919",
  },
};

interface SEOProps {
  pageMeta?: Array<any>;
  pageTitle?: string;
  pageKeywords?: Array<string>;
  pageDescription?: string;
  pageSchemaContent?: any;
  showOnlyPageSchema?: boolean;
}

const SEO = ({
  pageMeta = [],
  pageTitle,
  pageKeywords,
  pageDescription,
  pageSchemaContent,
  showOnlyPageSchema,
}: SEOProps) => {
  const keywords = ["imchetanyadav", "react", "nodejs", "flutter", "portfolio"];
  if (pageKeywords && pageKeywords.length > 0) keywords.push(...pageKeywords);
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const title = pageTitle || data.site.siteMetadata.title;
        const description =
          pageDescription || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={pageTitle ? pageTitle : title}
            titleTemplate={
              pageTitle ? `%s | ${data.site.siteMetadata.title}` : title
            }
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
            ]
              .concat({
                name: `keywords`,
                content: keywords.join(`, `),
              })
              .concat(pageMeta)}
            script={[
              {
                type: `application/ld+json`,
                innerHTML: showOnlyPageSchema
                  ? JSON.stringify(pageSchemaContent)
                  : JSON.stringify({
                      ...schemaContent,
                      ...pageSchemaContent,
                    }),
              },
            ]}
          />
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
