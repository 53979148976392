import * as React from "react";
import { Link } from "gatsby";
import { Flex, Box } from "rebass";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para, PrimaryButton } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  extraDetails?: string;
  buttonText?: string;
}

const AboutSection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  extraDetails,
  buttonText = "Learn more",
}) => {
  return (
    <PatternBackground patternType={2}>
      <Section>
        <Flex
          flexDirection={["column", "column", "row"]}
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Box width={[1, 1, 1 / 2]}>
            <Fade bottom>
              <FocusText>{focusText}</FocusText>
            </Fade>
            <Fade bottom>
              <Title>{title}</Title>
            </Fade>
            <Fade bottom delay={200}>
              <Para style={{ whiteSpace: "pre-line" }}>{subtitle}</Para>
            </Fade>
            <Fade bottom delay={400}>
              {extraDetails ? (
                <Para style={{ whiteSpace: "pre-line" }}>{extraDetails}</Para>
              ) : (
                <Link to="/about">
                  <PrimaryButton>{buttonText}</PrimaryButton>
                </Link>
              )}
            </Fade>
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <Fade delay={400}>
              <img src="/img/person.svg" alt="person" />
            </Fade>
          </Box>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default AboutSection;
