export const breakpoints = [32, 48, 64, 80].map(n => n + "em");

export const space = [
  0,
  4,
  8,
  12,
  16,
  24,
  32,
  48,
  64,
  96,
  128,
  192,
  256,
  384,
  512,
  640,
  768,
];

export const fontSizes = [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72];

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  black: 900
};

export const colors = Object.assign(
  {},
  {
    foreground: "#222",
    background: "#fafafa",
    primary: "#3d70b2",
    secondary: "#5596e6",
    accent: "#41d6c3",
    grey: "#4d596d",
    black: "#262626",
    white: "#edf2f4",
  }
);

export const darkThemeColors = Object.assign(
  {},
  {
    foreground: "#fff",
    background: "#121212",
    primary: "#3d70b2",
    secondary: "#5596e6",
    accent: "#41d6c3",
    grey: "#8d99ae",
    black: "#edf2f4",
    white: "#262626",
  }
);

export const fonts = {
  primary: "'Montserrat', sans-serif",
  secondary: "'Montserrat', sans-serif",
};

export default {
  colors,
  fonts,
  fontSizes,
  breakpoints,
  fontWeights,
  space,
};
